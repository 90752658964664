import { useQuery } from "@tanstack/react-query";
import {
  getMarketingChannelsStores,
  MarketingChannelStore,
} from "@/services/core-api-adapter";

interface UseGetMarketingChannelsStoresProps {
  id: string;
  province: string;
}

export default function useGetMarketingChannelsStores({
  id,
  province,
}: UseGetMarketingChannelsStoresProps) {
  const controller = new AbortController();

  const { data, isLoading, isPending, isError, error } = useQuery({
    queryKey: ["getMarketingChannelsStoresData", id, province],
    queryFn: async () => {
      const responseData = await getMarketingChannelsStores(
        { id, province },
        {
          signal: controller.signal,
        }
      );

      return responseData as MarketingChannelStore[];
    },
    enabled: !!id && !!province,
  });

  return {
    marketingChannelsStores: data,
    marketingChannelsStoresFetchError: error || isError,
    isMarketingChannelsStoresLoading: isLoading || isPending,
  };
}
