import { useQuery } from "@tanstack/react-query";
import {
  MemberProductPaymentRatesItem,
  GetMemberProductPaymentRatesStatus,
  getMemberProductPaymentRates,
} from "@/services/core-api-adapter";

export default function useGetMemberProductPaymentRates() {
  const controller = new AbortController();

  const { data, error, isError, isPending, isLoading } = useQuery({
    queryKey: ["getMemberProductPaymentRates"],
    queryFn: async () => {
      const responseData = await getMemberProductPaymentRates(
        GetMemberProductPaymentRatesStatus.ACTIVE,
        {
          signal: controller.signal,
        }
      );

      return responseData as MemberProductPaymentRatesItem[];
    },
  });

  return {
    isProductPaymentRatesLoading: isPending || isLoading,
    productPaymentRatesFetchError: error || isError,
    productPaymentRates: data || [],
  };
}
