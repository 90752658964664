import { useQuery } from "@tanstack/react-query";
import { getPathologyOrders } from "@/services/core-api-adapter";

export default function useGetPathologyOrders() {
  const controller = new AbortController();
  const { data, error, isError, isPending, isLoading } = useQuery({
    queryKey: ["getPathologyOrders"],
    queryFn: async () => {
      const responseData = await getPathologyOrders({
        signal: controller.signal,
      });
      return responseData;
    },
  });

  return {
    ordersList: data?.orders,
    skipCount: data?.skipCount,
    listLength: data?.listLength,
    total: data?.total,
    ordersFetchError: error || isError,
    isOrdersLoading: isPending || isLoading,
  };
}
