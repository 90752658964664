import { useQuery } from "@tanstack/react-query";
import { getMemberProfile, MemberProfile } from "@/services/core-api-adapter";

function addHardcodedUnderwriterToMemberProfile(
  memberProfile: MemberProfile
): MemberProfile {
  const _productDetails = memberProfile.product;

  _productDetails.underwriter = _productDetails.underwriter
    ? _productDetails.underwriter
    : {
        id: "",
        name: "",
        code: "",
        imageUri: "",
      };

  if (
    memberProfile.membership.productCode === "PRIST" ||
    memberProfile.membership.productCode === "GLDHR"
  ) {
    _productDetails.underwriter = {
      id: "f350c1f0-c8f8-46d6-8aae-519b32d8e187",
      name: "Genric",
      code: "",
      imageUri: "",
    };
  }

  return {
    ...memberProfile,
    product: {
      ..._productDetails,
    },
  };
}

export default function useGetMemberProfile() {
  const controller = new AbortController();

  const { data, isPending, isLoading, isError, error } = useQuery({
    queryKey: ["getMemberProfile"],
    queryFn: async () => {
      const responseData = await getMemberProfile({
        signal: controller.signal,
      });

      const transformedMemberProfile =
        addHardcodedUnderwriterToMemberProfile(responseData);
      return transformedMemberProfile;
    },
  });

  return {
    memberProfile: data || null,
    memberProfileFetchError: Boolean(error) || isError,
    isMemberProfileLoading: isPending || isLoading,
  };
}
