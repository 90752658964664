import { useState } from "react";
import {
  isPleaseCallMeServiceAvailable,
  haveANurseCallMeBack,
} from "../../../services/core-api-adapter";

interface MakeCallMeBackRequestProps {
  assistanceMembershipId: string;
}

export default function useSeekMedicalAssistance() {
  const [isGenericError, setIsGenericError] = useState(false);
  const [isTooManyRequestsError, setIsTooManyRequestsError] = useState(false);
  const [isAssistanceRequestInProgress, setIsAssistanceRequestInProgress] =
    useState(false);

  function checkIfPleaseCallMeServiceIsAvailable() {
    setIsAssistanceRequestInProgress(true);

    return isPleaseCallMeServiceAvailable()
      .then(() => {
        setIsGenericError(false);
        setIsAssistanceRequestInProgress(false);
        setIsTooManyRequestsError(false);
      })
      .catch((err) => {
        setIsAssistanceRequestInProgress(false);
        if (err.code === 429) {
          setIsTooManyRequestsError(true);
        } else {
          setIsGenericError(true);
        }
      });
  }

  function makeCallMeBackRequest({
    assistanceMembershipId,
  }: MakeCallMeBackRequestProps) {
    setIsAssistanceRequestInProgress(true);

    return haveANurseCallMeBack({
      assistanceMembershipId: assistanceMembershipId as any,
    })
      .then(() => {
        setIsGenericError(false);
        setIsAssistanceRequestInProgress(false);
      })
      .catch(() => {
        setIsAssistanceRequestInProgress(false);
        setIsGenericError(true);
      });
  }

  return {
    checkIfPleaseCallMeServiceIsAvailable,
    makeCallMeBackRequest,
    isTooManyRequestsError,
    isAssistanceRequestInProgress,
    isGenericError,
  };
}
