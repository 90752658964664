import { useEffect, useState } from "react";
import {
  getHealthProfile,
  HealthProfileSuccessResponseData,
} from "../../../services/core-api-adapter";

export default function useGetHealthProfile() {
  const [healthProfile, setHealthProfile] =
    useState<HealthProfileSuccessResponseData | null>(null);
  const [healthProfileFetchError, setHealthProfileFetchError] = useState(false);
  const [isHealthProfileLoading, setIsHealthProfileLoading] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    setIsHealthProfileLoading(true);
    getHealthProfile({ signal: controller.signal })
      .then((res) => {
        if ("items" in res) {
          setHealthProfile(res);
          setHealthProfileFetchError(false);
          setIsHealthProfileLoading(false);
        }
      })
      .catch(() => {
        if (controller.signal.aborted === false) {
          setHealthProfileFetchError(true);
          setHealthProfile(null);
          setIsHealthProfileLoading(false);
        }
      });

    return () => {
      controller.abort();
    };
  }, []);

  return { healthProfile, healthProfileFetchError, isHealthProfileLoading };
}
