import { useEffect, useState } from "react";

const useCameras = (): MediaDeviceInfo[] => {
  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([]);

  useEffect(() => {
    const getCameras = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true });
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        setCameras(videoDevices);
      } catch (error) {
        console.error("Error accessing media devices.", error);
      }
    };

    getCameras();
  }, []);

  return cameras;
};

export default useCameras;
