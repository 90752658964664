import { useQuery } from "@tanstack/react-query";
import {
  PathologyPanel,
  getPathologyPanelsList,
} from "../../../services/core-api-adapter";

export default function useGetPanelsList() {
  const controller = new AbortController();
  const { data, error, isError, isPending, isLoading } = useQuery({
    queryKey: ["getPanelsList"],
    queryFn: async () => {
      const responseData = await getPathologyPanelsList({
        signal: controller.signal,
      });
      return responseData as PathologyPanel[];
    },
  });

  return {
    panelList: data as Array<PathologyPanel>,
    panelListFetchError: error || isError,
    isPanelListLoading: isPending || isLoading,
  };
}
